import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";
import { ChatConfig, ChatConfigSettings } from "@/includes/types/Chat/types";
import {
  AchievementStaticsReq,
  AchievementStaticsReqTypeEnum,
  AchievementStaticsRes
} from "@/includes/types/Achievements.types";

type AchievementsList = Pick<Pick<ChatConfig, 'common_config'>['common_config'], 'achievements'>

export default class AchievementsApi {

  static async addUserAchievement(chat_id: number, user_id: number, achievement_id: number) {
    return PostApi.sendRequest<AchievementsList>('tg', 'adduserachievement', { chat_id, user_id, achievement_id })
  }

  static async removeUserAchievement(chat_id: number, user_id: number, achievement_id: number) {
    return PostApi.sendRequest<AchievementsList>('tg', 'removeuserachievement', { chat_id, user_id, achievement_id })
  }

  static async getAchievementStatistics<T extends AchievementStaticsReqTypeEnum>(chat_id: number, payload: AchievementStaticsReq) {
    return PostApi.sendRequest<AchievementStaticsRes<T>>('tg', "getachievementstatistics", {
      chat_id,
      ...payload
    });
  }

  static async removeAchievement(chat_id: number, achievement_id: number) {
    return PostApi.sendRequest<{ config: ChatConfigSettings['common_config'] } & Pick<ChatConfigSettings, 'config_version'>>('tg', 'removeachievement', { chat_id, achievement_id })
  }
}
