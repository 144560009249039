


































import { AchievementItem } from "@/includes/types/Achievements.types";
import { InputSetups } from "@/mixins/input-setups";

import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue';

import { Component, VModel, Mixins, Emit, PropSync, Prop } from 'vue-property-decorator';
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    TextInput,
    ValidationObserver,
  }
})
export default class AchievementCreateModal extends Mixins(InputSetups) {
  @VModel() achievement!: AchievementItem

  @PropSync('visible') isAddAchievementModalOpen!: boolean

  @Prop() isNewAchievement!: boolean

  @Emit()
  modalOk() {
  }

  @Emit()
  modalAfterClose() {

  }

  get modalTitle() {
    return this.isNewAchievement ? this.$t('achievements_add') : this.$t('achievements_edit')
  }

  get modalOkText() {
    return this.isNewAchievement ? this.$t('add') : this.$t('save_button')
  }

}
