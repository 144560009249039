var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('a-modal',{attrs:{"title":_vm.modalTitle,"ok-text":_vm.modalOkText,"after-close":_vm.modalAfterClose,"ok-button-props":{
      props: {
        disabled: invalid
      }
    }},on:{"ok":_vm.modalOk},model:{value:(_vm.isAddAchievementModalOpen),callback:function ($$v) {_vm.isAddAchievementModalOpen=$$v},expression:"isAddAchievementModalOpen"}},[_c('text-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.achievement,
          'key': 'title',
          'prefix': 'achievement_',
          'validation': 'required',
          'supportButtons': false
        }
      }}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }