















































import {
  LocalCurrentUserStatisticsInfo,
} from "@/includes/types/Achievements.types";

import UserMainInfo from "piramis-base-components/src/components/UserMainInfo.vue";

import { Vue, Component, PropSync, Prop, Emit } from 'vue-property-decorator';

@Component({
  components: {
    UserMainInfo
  }
})
export default class AchievementUserStatisticsModal extends Vue {
  @Prop({ type: Object, required: true }) userStatistics!: LocalCurrentUserStatisticsInfo['statistics']

  @Prop({ type: Number, required: true }) total!: number

  @PropSync('visible', { type: Boolean, required: true }) isModalOpen!: boolean

  @Prop({ type: Boolean, required: false, default: false }) isLoading!: boolean

  @Emit()
  paginationChange(pagination: any, size: any) {
    return {
      page: pagination,
      size,
    }
  }

  @Emit()
  modalAfterClose() {

  }

  get users() {
    return this.userStatistics.data ?? []
  }

  get page() {
    return this.userStatistics.page
  }

  get limit() {
    return this.userStatistics.limit
  }
}
