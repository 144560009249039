import { BaseUserInfo } from "@/includes/types/UserProfile/types";

export type AchievementItem = {
  title: string
  id: number
}

export const enum AchievementStaticsReqTypeEnum {
  Total = 'Total',
  Users = 'Users'
}

export type TotalAchievementStaticsRes = Record<AchievementItem['id'], number>

export type UsersAchievementStaticsRes = Array<BaseUserInfo>

export type AchievementStaticsRes<T> = {
  data: T extends AchievementStaticsReqTypeEnum.Total
      ? TotalAchievementStaticsRes
      : UsersAchievementStaticsRes
}

export type AchievementStaticsReq = UsersAchievementStaticsReq | TotalAchievementStaticsReq

export type TotalAchievementStaticsReq = {
  mode: AchievementStaticsReqTypeEnum.Total,
}

export type UsersAchievementStaticsReq = {
  mode: AchievementStaticsReqTypeEnum.Users,
  achievement_id: number
  limit: number
  offset: number
}

export type LocalCurrentUserStatisticsInfo = {
  id: number | null
  isModalOpen: boolean
  isLoading: boolean
  statistics: {
    data: UsersAchievementStaticsRes | null
    limit: UsersAchievementStaticsReq['limit']
    page: number
  },
}
