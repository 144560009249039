






















































































import { InputSetups } from "@/mixins/input-setups";
import {
  AchievementItem,
  AchievementStaticsReq,
  AchievementStaticsReqTypeEnum,
  LocalCurrentUserStatisticsInfo,
  TotalAchievementStaticsRes,
} from "@/includes/types/Achievements.types";
import { AchievementsMixin } from "@/includes/logic/achievements";
import AchievementEditModal from "@/components/Achievements/AchievementEditModal.vue";
import AchievementsApi from "@/includes/Api/Achievements.api";
import { errorNotification, successNotification } from "@/includes/NotificationService";
import AchievementUserStatisticsModal from "@/components/Achievements/AchievementUserStatisticsModal.vue";

import AddEntityCard from 'piramis-base-components/src/components/AddEntityCard.vue';
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";

import { Component, Mixins, Prop } from 'vue-property-decorator';

@Component({
  components: {
    AchievementUserStatisticsModal,
    AchievementEditModal,
    TextInput,
    AddEntityCard,
  }
})
export default class AchievementsList extends Mixins(InputSetups, AchievementsMixin) {
  @Prop({ type: Boolean, required: false, default: false }) disabled!: boolean

  totalStatistics: TotalAchievementStaticsRes | null = null

  currentUserStatistics: LocalCurrentUserStatisticsInfo = this.resetCurrentUserStatisticsModel()

  resetCurrentUserStatisticsModel(): LocalCurrentUserStatisticsInfo {
    return {
      isModalOpen: false,
      isLoading: false,
      id: null,
      statistics: {
        data: null,
        limit: 10,
        page: 1
      }
    }
  }

  get achievements(): Array<AchievementItem> {
    return this.$store.state.chatState.chat?.common_config?.achievements ?? []
  }

  paginationChange({ page, size }: { page: number, size: number }) {
    this.currentUserStatistics.statistics.page = page

    if (size !== this.currentUserStatistics.statistics.limit) {
      this.currentUserStatistics.statistics.page = 1
    }

    this.currentUserStatistics.statistics.limit = size

    this.getUsersAchievementStatics()
  }

  setToEdit(item: AchievementItem) {
    this.achievement = { ...item }

    this.isAddAchievementModalOpen = true
  }

  openUserStatistics(id: number) {
    if (this.$store.getters.canStatistics) {
      this.currentUserStatistics.id = id
      this.currentUserStatistics.isModalOpen = true

      this.getUsersAchievementStatics()
    }
  }

  handleOkClick() {
    if (this.isNewAchievement) {
      this.addAchievement()
    } else {
      this.updateAchievement()
    }
  }

  updateAchievement() {
    this.$store.dispatch('updateAchievement', this.achievement)
      .then(() => {
        this.saveConfig()
          .then(res => {
            this.isAddAchievementModalOpen = false
          })
      })
  }

  removeAchievement(id: AchievementItem['id']) {
    AchievementsApi.removeAchievement(this.$store.getters.chatId, id)
      .then(({ data }) => {
        this.$store.commit('PARTIAL_CONFIG_UPDATE', { config_version: data.config_version })
        this.$store.dispatch('setAchievements', data.config.achievements)
          .then(() => successNotification())
      })
      .catch(errorNotification)
  }

  handleRemoveAchievement(id: AchievementItem['id']) {
    if (this.totalStatistics && this.totalStatistics?.[id] > 0) {
      this.$confirm({
        title: this.$t('achievements_confirm_remove_user_title').toString(),
        content: this.$t('achievements_confirm_remove_user_content').toString(),
        okText: this.$t('delete').toString(),
        onOk: () => this.removeAchievement(id)
      })
    } else {
      this.removeAchievement(id)
    }
  }

  getAchievementStatistics<T extends AchievementStaticsReqTypeEnum>(payload: AchievementStaticsReq) {
    return AchievementsApi.getAchievementStatistics<T>(this.$store.getters.chatId, payload)
      .then(({ data }) => data)
      .catch(errorNotification)
  }

  getTotalAchievementStatics() {
    this.getAchievementStatistics<AchievementStaticsReqTypeEnum.Total>({ mode: AchievementStaticsReqTypeEnum.Total })
      .then(res => {
        if (res) {
          this.totalStatistics = res.data
        }
      })
  }

  getUsersAchievementStatics() {
    const { id, statistics } = this.currentUserStatistics
    this.currentUserStatistics.isLoading = true

    if (id) {
      this.getAchievementStatistics<AchievementStaticsReqTypeEnum.Users>({
        mode: AchievementStaticsReqTypeEnum.Users,
        achievement_id: id,
        limit: statistics.limit,
        offset: (statistics.page - 1) * statistics.limit
      })
        .then((res) => {
          if (res) {
            this.currentUserStatistics.statistics.data = res.data
          } else {
            this.currentUserStatistics.statistics.data = []
          }

          this.currentUserStatistics.isLoading = false
        })
    }
  }

  created() {
    this.getTotalAchievementStatics()
  }
}
